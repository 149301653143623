'use strict'; /* eslint-disable wrap-iife */
(function Institute() {
  // Global selectors
  var cardsHolder = document.querySelector('[rel="js-cards-container"]');
  //const sortHolder = document.querySelector('.c-result-bar ul');
  //const sortNumber = document.querySelector('[rel="js-cardCountTotal"]');

  function l() {for (var _len = arguments.length, anything = Array(_len), _key = 0; _key < _len; _key++) {anything[_key] = arguments[_key];}
    console.log(anything);
  }

  var loader = function myLoader() {
    var elLoadSpinner = document.querySelector('[rel="js-load-spinner"]');
    var elLoadMoreContainer = document.querySelector(
    '[rel="js-load-container"]');

    //const btnLoadMore = elLoadMoreContainer.querySelector('button');

    var loadBtns = elLoadMoreContainer.querySelectorAll('button');
    var loadFunction = void 0;
    //let nextUrl;
    var urls = {
      next: '',
      all: '' };


    function startLoadSpinner() {
      elLoadSpinner.classList.remove('u-display--none');
      elLoadMoreContainer.classList.add('u-display--none');
    }

    function stopLoadSpinner() {
      elLoadSpinner.classList.add('u-display--none');
    }

    function loadMore() {var url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : urls.next;
      startLoadSpinner();
      loadFunction(url);
    }

    function loadMoreBtnHandler(e) {
      e.preventDefault();
      var loadType = e.target.getAttribute('data-load-type');
      loadMore(urls[loadType]);
    }

    function setNextUrl(urlNext, urlAll) {
      urls.next = urlNext;
      urls.all = urlAll;
    }

    function toggleLoadMore(data) {
      // internal
      var dataNextUrl = data.nextPageUrl;
      var dataAllUrl = data.allUrl;

      function hasNextPageUrl() {
        return dataNextUrl && dataNextUrl.length > 0;
      }

      if (hasNextPageUrl()) {
        setNextUrl(dataNextUrl, dataAllUrl);
        elLoadMoreContainer.classList.remove('u-display--none');
      } else {
        elLoadMoreContainer.classList.add('u-display--none');
      }

      stopLoadSpinner();
    }

    function init(loaderFunction, firstUrl, allUrl) {
      for (var i = 0; i < loadBtns.length; i++) {
        var btn = loadBtns[i];
        btn.addEventListener('click', loadMoreBtnHandler);
      }
      urls.all = allUrl;
      loadFunction = loaderFunction;
      loadMore(firstUrl);
    }

    return {
      startSpinner: startLoadSpinner,
      stopSpinner: stopLoadSpinner,
      setNextUrl: setNextUrl,
      load: loadMore,
      toggle: toggleLoadMore,
      init: init };

  }();

  var templateImageLink = {
    element: 'a',
    classList: ['c-img-card__anchor'],
    attributes: [
    {
      attr: 'href',
      contentKey: 'detailHref' }],


    children: [
    {
      element: 'figure',
      children: [
      {
        element: 'img',
        classList: [],
        attributes: [
        {
          attr: 'src',
          contentKey: 'imageSrc' },

        {
          attr: 'alt',
          contentKey: 'name' }] },



      {
        element: 'figcaption',
        classList: [],
        contentKey: 'textImageBtn' }] }] };






  var templateWaitTime = {
    element: 'p',
    classList: [],
    children: [
    {
      element: 'span',
      classList: ['c-img-card__special', 'u-display--none'],

      children: [
      {
        func: function func(card) {
          return [
          {
            element: 'span',
            classList: ['clockwise_current_wait'],
            attributes: [
            {
              attr: 'data-clockwise',
              value: '' + card.clockwiseId }] }];




        } },

      {
        element: 'span',
        classList: ['c-img-card__wait-text'],
        contentKey: 'textWaitTimes' }] }] };






  var templateDetails = {
    element: 'div',
    classList: ['c-img-card__details'],
    children: [
    {
      element: 'h2',
      classList: ['c-img-card__name'],
      children: [
      {
        element: 'a',
        classList: [],
        contentKey: 'name',
        attributes: [
        {
          attr: 'href',
          contentKey: 'detailHref' }] }] },





    {
      func: function func(card) {
        if (card.distance !== null) {
          return [{
            element: 'p',
            classList: ['c-img-card__distance'],
            contentKey: 'distance' }];

        }
      } },

    {
      func: function func(card) {
        if (card.hasWaitTime === true) {
          return [templateWaitTime];
        }
      } },

    {
      func: function func(card) {
        if (Array.isArray(card.addresses)) {
          return card.addresses.map(function (str) {return {
              element: 'p',
              classList: ['c-img-card__address'],
              contentKey: 'innerhtml',
              innerhtml: str,
              attributes: [
              {
                attr: 'translate',
                value: 'no' }] };});



        }
      } },

    {
      func: function func(card) {
        if (Array.isArray(card.contactNumbers)) {var
          checkIsPhone = function checkIsPhone(isPhone, cardNumber) {
            if (!isPhone) {
              return {
                element: 'p',
                classList: ['c-img-card__number-not-phone'],
                contentKey: 'innerhtml',
                innerhtml: cardNumber };

            }
            //returns formatted a tag with tel:number
            return {
              element: 'a',
              contentKey: 'innerhtml',
              innerhtml: cardNumber,
              attributes: [
              {
                attr: 'href',
                value: 'tel:+1' + cardNumber.replace(/-/g, '') }] };



          };

          return card.contactNumbers.map(function (cont) {return {
              element: 'p',
              classList: ['c-img-card__number'],
              children: [
              {
                element: 'span',
                classList: ['c-img-card__number-text'],
                innerhtml: cont.text,
                contentKey: 'innerhtml' },

              checkIsPhone(cont.isPhone, cont.number)] };});


        }
      } }] };




  var templateMain = {
    heading: {
      element: 'div',
      classList: ['c-img-cards__heading'],
      contentKey: 'group' },

    cards: {
      element: 'div',
      classList: ['c-img-cards'] },

    card: {
      element: 'article',
      classList: ['c-img-card'],
      children: [templateImageLink, templateDetails] } };


  /*  +------------------------+
                                                              |      CLOCKWISE         |
                                                              +------------------------+  */
  var clockwiseHandler = function () {
    var gettingClockwise = {
      loading: false,
      queue: [] };


    function initUpdateWaitTime(elCurrentWait) {

      var timeDisplayFormat = clockwiseHandler.timeOptions.waitTimeDisplayStyle;
      var timeDisplayHoursMins = clockwiseHandler.timeOptions.waitTimeIsHoursAndMinutes;

      function getHourString(numHours) {
        if (numHours === 0) return '';
        var strHours = numHours > 1 ? ' hours ' : ' hour ';
        return numHours + strHours;
      }

      function getMinuteString(nHours, numMins) {
        if (nHours > 0 && numMins === 0) return '';
        return numMins + ' min';
      }

      function timeConvert(n) {
        var num = n;
        var hours = num / 60;
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        return getHourString(rhours) + getMinuteString(rhours, rminutes);
      }

      function createRangedTime(minTime, maxTime) {var delimiter = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '-';
        return minTime + ' ' + delimiter + ' ' + maxTime;
      }

      return function UpdateWaitTimes(waitInfoObj) {

        function getWaitTimeText() {
          if (waitInfoObj.next_available_visit === 'N/A') {
            return waitInfoObj.next_available_visit;
          }

          var nextAvailable = waitInfoObj.next_available_visit;
          var maxTime = parseInt(nextAvailable, 10) + 15;

          if (timeDisplayHoursMins !== 'true') {
            return timeDisplayFormat === 'range' ? createRangedTime(nextAvailable, maxTime) : nextAvailable;
          }

          return timeDisplayFormat === 'range' ? createRangedTime(timeConvert(nextAvailable), timeConvert(maxTime), 'to') : timeConvert(nextAvailable);
        }

        elCurrentWait.parentElement.classList.remove('u-display--none');
        elCurrentWait.innerHTML = getWaitTimeText();
      };
    }

    function next() {
      if (gettingClockwise.queue.length > 0 && gettingClockwise.loading === true) {
        var nextWait = gettingClockwise.queue.pop();
        nextWait();
      } else {
        //if last end
        gettingClockwise.loading = false;
      }
    }

    function add(clockwiseId, element) {
      var waitTimeController = initUpdateWaitTime(element);
      var queueClockwise = function queueClockwise() {
        var url = 'https://api.clockwisemd.com/v1/hospitals/' + clockwiseId + '/waits';

        function success(data) {

          waitTimeController(data.hospital_waits);
          // element.innerHTML = `${data.hospital_waits.current_wait} `;
          next();
        }

        return ajax.get(url, { success: success }, true);
      };

      gettingClockwise.queue.push(queueClockwise);

      if (gettingClockwise.loading === false) {
        //if first start
        gettingClockwise.loading = true;
        next();
      }
    }

    return {
      add: add };

  }();
  /*  +-----------------------+
           |       AJAX            |
           +-----------------------+  */
  var ajax = function Ajax() {
    var xhr = void 0;

    function get(url, options) {var async = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      if (!url || url.length === 0) {
        throw new Error('Bad Get URL');
      }

      function getStateChangeListener() {
        if (xhr.readyState === 4) {
          var responseText = JSON.parse(xhr.responseText);
          if (xhr.status === 200) {
            options.success(responseText);
          } else {
            console.error('An error occurred during your request!\n                status: ' +
            xhr.status + '\n                status text: ' +
            xhr.statusText);
            options.error(responseText);
          }
        }
      }

      xhr = new XMLHttpRequest();
      xhr.open('GET', url, async);
      xhr.setRequestHeader(
      'Content-Type',
      'application/x-www-form-urlencoded; charset=UTF-8');

      xhr.onreadystatechange = getStateChangeListener;
      xhr.send(options.data);
    }

    return {
      get: get };

  }();

  function createTemplateElement(obj) {var doctorObj = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var element = document.createElement(obj.element);
    // internal
    function checkAndSetParameter(param, func) {
      if (param) {for (var _len2 = arguments.length, args = Array(_len2 > 2 ? _len2 - 2 : 0), _key2 = 2; _key2 < _len2; _key2++) {args[_key2 - 2] = arguments[_key2];}
        func.apply(undefined, [param].concat(args));
      }
    }
    // internal
    function setCssClass(cssClassList) {
      cssClassList.forEach(function (cssClass) {
        element.classList.add(cssClass);
      });
    }
    // internal
    function setContent(key) {
      element.innerHTML = key === 'innerhtml' ? obj.innerhtml : doctorObj[key];
    }
    // internal
    function setAttributes(attributes) {
      // internal
      function checkForAltAttr(attr, contentValue) {
        return attr !== 'alt' ? contentValue : 'Photo of ' + contentValue;
      }
      // action
      attributes.forEach(function (_ref) {var attr = _ref.attr,contentKey = _ref.contentKey,_ref$value = _ref.value,value = _ref$value === undefined ? false : _ref$value;
        var contentValue =
        value !== false ?
        value :
        checkForAltAttr(attr, doctorObj[contentKey]);
        element.setAttribute(attr, contentValue);
      });
    }

    function birthChildren(children) {
      children.forEach(function (kid) {
        if (kid.func) {
          var hasSpecial = kid.func(doctorObj);
          if (hasSpecial) {
            hasSpecial.forEach(function (item) {
              var childElement = createTemplateElement(item, doctorObj);
              if (childElement.dataset.clockwise) {
                clockwiseHandler.add(doctorObj.clockwiseId, childElement);
              }
              element.appendChild(childElement);
            });
          }
        } else {
          element.appendChild(createTemplateElement(kid, doctorObj));
        }
      });
    }

    // Sets Css classes
    checkAndSetParameter(obj.classList, setCssClass);
    // Copies content from data to innerHTML of element
    checkAndSetParameter(obj.contentKey, setContent);
    // Sets html attributes (src, href....)
    checkAndSetParameter(obj.attributes, setAttributes);
    // loops child elemnts in template to create html structure
    checkAndSetParameter(obj.children, birthChildren);

    return element;
  }

  function getSectionHolder(section) {
    // internal
    function getLastChildOfClass(classname) {
      return [].slice.call(cardsHolder.querySelectorAll(classname)).pop();
    }
    // internal
    function lastCardSection() {
      return getLastChildOfClass('.c-img-cards');
    }
    // internal
    function newCardSection() {
      cardsHolder.appendChild(
      createTemplateElement(templateMain.heading, section));

      var cards = createTemplateElement(templateMain.cards);
      cardsHolder.appendChild(cards);
      return cards;
    }
    // internal
    function isNewSection() {
      var lastHeading = getLastChildOfClass('.c-img-cards__heading');
      return !lastHeading || lastHeading.innerHTML !== section.group;
    }
    // action
    var sectionHolder = isNewSection() ? newCardSection() : lastCardSection();

    return {
      Append: function Append(el) {
        sectionHolder.appendChild(el);
      } };

  }

  function initializeLocations(data, locations, holder) {
    /*  takes global parameters and applies them to individual
                                                             cards (reused button text)                              */
    function setRepeatParameters(loc) {
      var repeatParameters = ['textImageBtn', 'textWaitTimes', 'waitTimeDisplayStyle', 'waitTimeIsHoursAndMinutes'];
      repeatParameters.forEach(function (param) {
        if (data[param]) {
          loc[param] = data[param];
        }
      });
      return loc;
    }

    clockwiseHandler.timeOptions = {
      'waitTimeDisplayStyle': data.waitTimeDisplayStyle,
      'waitTimeIsHoursAndMinutes': data.waitTimeIsHoursAndMinutes

      // action
    };locations.forEach(function (loc) {
      loc = setRepeatParameters(loc, data);
      holder.Append(createTemplateElement(templateMain.card, loc));
    });
  }

  function getLocations(url) {
    return ajax.get(
    url,
    {
      success: function success(data) {
        loader.toggle(data);
        // sortHolder.classList.add(`sort--${data.sort.sortOrder}`);
        // if(data.cardCountTotal){
        //   sortNumber.innerHTML = data.cardCountTotal;
        // }
        // action
        data.content.forEach(function (section) {
          initializeLocations(data, section.cards, getSectionHolder(section));
        });
      },
      error: function error(er) {
        loader.stopSpinner();
      } },

    true);

  }

  function getCoords(callback) {
    var queryCords;

    var isLoading = false;

    function setQueryString(val) {
      queryCords = val;
      isLoading = false;
    }

    function loadedCoords(pos) {
      setQueryString('&lat=' + pos.coords.latitude + '&lon=' + pos.coords.longitude);
      callback(queryCords);
    }

    function failedCoords() {
      console.log('failed loading coords');
      setQueryString('');
      callback(queryCords);
    }

    function getLocation() {
      isLoading = true;
      navigator.geolocation.getCurrentPosition(loadedCoords, failedCoords);
    }

    if (!navigator.geolocation) {
      failedCoords();
    }

    function getQueryString() {
      if (!queryCords && isLoading === false) {
        getLocation();
      } else {
        callback(queryCords);
      }
    }

    getQueryString();
  }

  function distanceBtnHandler(e) {
    e.preventDefault();
    var url = e.target.href;
    getCoords(function (c) {
      url = '' + url + c;
      window.location.href = url;
    });
    //sortHolder.classList.remove(sortHolder.classList.item(0));
  }

  function initializeSortBtnListeners() {
    var distanceBtn = document.querySelector('a[data-sort="distance"]');
    if (!distanceBtn) return;
    distanceBtn.addEventListener('click', distanceBtnHandler);

    var mobileSortDropDown = document.querySelector('[rel="js-sortDropDown"]');

    mobileSortDropDown.addEventListener('click', function (e) {
      if (mobileSortDropDown.classList.contains('active')) {
        mobileSortDropDown.classList.remove('active');
      } else {
        mobileSortDropDown.classList.add('active');
      }
    });
  }

  function intializeLoader() {
    var firstLoadUrl = cardsHolder.getAttribute('data-url');
    var allLoadUrl = cardsHolder.getAttribute('data-url-all');
    if (!firstLoadUrl) {
      console.error('No url on container to load');
      return;
    }

    loader.init(getLocations, firstLoadUrl, allLoadUrl);
  }

  (function Init() {
    intializeLoader();
    initializeSortBtnListeners();
  })();

})();